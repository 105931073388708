<template>
  <div :class="wrapperClasses">
    <div class="flex justify-between items-center mx-auto lg:mt-10 mt-4">
      <div class="lg:w-7/12 w-full text-center lg:text-left">
        <h3 class="uppercase tracking-lg font-black lg:text-lg leading-h3 mb-md text-h5">{{ item.title }}</h3>
        <p class="lg:hidden block mb-5">
            <img :src="item.img">
        </p>
        <p class="mb-5 text-average lg:text-above-md leading-base lg:leading-h4" v-html="item.text" />
        <component v-bind="linkProps(item.link)"
          class="uppercase lg:text-md lg:font-bold lg:px-10 text-white bg-black hover:bg-brown-2 block tracking-sm lg:leading-h1 leading-none text-center slow-color-change text-h5 py-5 lg:py-0"
        >
          {{ $t('Learn about the rewards program') }}
        </component>
      </div>
      <div class="w-30% min-w-7 lg:block hidden">
        <img :src="item.img">
      </div>
    </div>
  </div>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'
import StoryblokItem from 'theme/mixins/blocks/StoryblokItem'

export default {
  extends: Blok,
  mixins: [StoryblokItem],
  name: 'Advert'
}
</script>
